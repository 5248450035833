app.controller("HomeController", [
  "$scope",
  "$http",
  "Config",
  "$timeout",
  "cart",
  "toaster",
  "product",
  "helper",
  function ($scope, $http, Config, $timeout, cart, toaster, product, helper) {
    $scope.productGroups = [];
    $scope.base_url = Config.base_url;
    $scope.productService = product;
    $scope.cartService = cart;
    $scope.cart = cart.getCart();
    $scope.helper = helper;

    $scope.init = function () {
      $timeout(function () {
        helper.hideLoader();
        init();
      }, 100);
      $scope.getProductGroups();
    };
    $scope.getProductGroups = function () {
      $scope.productGroups = window.productGroups;
    };

    $scope.cartAdd = function (groupIdx, productIdx, productID, sectionID) {
      if (typeof sectionID == "undefined") {
        sectionID = 4;
      }
      $http({
        method: "POST",
        url:
          Config.api_url +
          "counter?section_id=" +
          sectionID +
          "&brand-key=" +
          Config.brand_key,
        headers: {
          authorization: Config.api_key,
        },
      });

      qty = $("input[name*='quantity_" + productID + "']").val();
      if (isNaN(qty) || Number(qty) <= 0) {
        return;
      }
      qty = qty || 1;

      $("input[name*='quantity_" + productID + "']").val(1);
      //get product from the group
      cart.add($scope.productGroups[groupIdx]["products"][productIdx], qty);
      helper.showAddedtoCart();
    };

    $scope.changeQty = function (productID, $event) {
      if ($event) {
        qty = $event.target.value;
        $scope.validateQty(qty, productID);
      }
    };

    $scope.incQty = function (productID) {
      var carrentValue = parseInt(
        $("input[name*='quantity_" + productID + "']").val()
      );
      var newvalue = carrentValue + 1;
      $("input[name*='quantity_" + productID + "']").val(newvalue);
      $scope.validateQty(newvalue, productID);
    };

    $scope.decQty = function (productID) {
      var carrentValue = parseInt(
        $("input[name*='quantity_" + productID + "']").val()
      );
      if (carrentValue > 1) {
        var newvalue = carrentValue - 1;
        $("input[name*='quantity_" + productID + "']").val(carrentValue - 1);
      } else {
        var newvalue = 1;
        $("input[name*='quantity_" + productID + "']").val(1);
      }
      $scope.validateQty(newvalue, productID);
    };

    $scope.validateQty = function (qty, productID) {
      if (!cart.qtyValidation(qty)) {
        $("input[name*='quantity_" + productID + "']").addClass("error");
        $(".help-" + productID).show();
        $(".cart-add-" + productID).attr("disabled", "disabled");
      } else {
        $("input[name*='quantity_" + productID + "']").removeClass("error");
        $(".help-" + productID).hide();
        $(".cart-add-" + productID).removeAttr("disabled");
      }
    };
  },
]);
